<template>
  <div class="home">
    <div class="container">
    <img src="../assets/Medellin.jpg" class="img-fluid">
      <div class="form mt-5">
        <h1>Déja tus datos</h1>
        <p>
          * Tus datos serán protegidos y cifrados para evitar la divulgación de
          los mismos
        </p>
        <div class="row">
          <div class="col col-12 mb-4">
            <b-form-input
              v-model="formData.cc"
              placeholder="Cédula"
              :class="{
                'is-invalid': submitted && $v.cc.$error,
              }"
            ></b-form-input>
            <div
              v-if="submitted && !$v.cc.required"
              class="invalid-feedback"
            >
              Cédula requerida
            </div>
          </div>
          <div class="col col-12 mb-4">
            <b-form-input
              v-model="formData.firstName"
              placeholder="Nombres"
              :class="{
                'is-invalid': submitted && $v.firstName.$error,
              }"
            ></b-form-input>
            <div
              v-if="submitted && !$v.firstName.required"
              class="invalid-feedback"
            >
              Nombres requeridos
            </div>
          </div>
          <div class="col col-12 mb-4">
            <b-form-input
              v-model="formData.lastName"
              placeholder="Apellidos"
              :class="{
                'is-invalid': submitted && $v.lastName.$error,
              }"
            ></b-form-input>
            <div
              v-if="submitted && !$v.lastName.required"
              class="invalid-feedback"
            >
              Apellidos requeridos
            </div>
          </div>
          <div class="col col-12 mb-4">
            <b-form-input
              v-model="formData.phone"
              placeholder="Teléfono"
              :class="{
                'is-invalid': submitted && $v.phone.$error,
              }"
            ></b-form-input>
            <div
              v-if="submitted && !$v.phone.required"
              class="invalid-feedback"
            >
              Teléfono requerido
            </div>
          </div>
          <div class="col col-12 mb-4">
            <b-form-input
              v-model="formData.email"
              placeholder="Correo electrónico"
              :class="{
                'is-invalid': submitted && $v.email.$error,
              }"
            ></b-form-input>
            <div
              v-if="submitted && !$v.cc.required"
              class="invalid-feedback"
            >
              Correo electrónico requerido
            </div>
          </div>
          <div class="col col-12 mb-4">
            <vue-recaptcha sitekey="AIzaSyBm2cKlm2hcWXyXYTgQEqn1jRwEix4m7hs" :loadRecaptchaScript="true" @verify="sendVote"></vue-recaptcha>
          </div>  
          <div class="col col-12 mb-4">
            <b-button
              @click="sendVote()"
              variant="success"
              class="right"
              style="float: right;"
              >Enviar</b-button
            >
          </div>
          <div class="col col-12">
            Haz <a href="https://wsp.registraduria.gov.co/censo/consultar/" target="_blank">click aquí</a> para consultar tu puesto de votación
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import voteService from "@/services/vote"
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "Home",
  components: { VueRecaptcha }, 
  validations: {
    firstName: { required },
    lastName: { required },
    phone: { required },
    email: { required },
    cc: { required },
  },
  data() {
    return {
      reg: /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      submitted: false,
      disable: false,
      formData: {
        firstName: "",
        lastName: "",
        phone: "",
        cc: "",
        email: "",
      },
    }
  },
  mixins: [validationMixin],
  methods: {
    sendVote(response) {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid && !response) {
        return
      }
      this.disable = true
      console.log(this.formData)
      const data = {
        vote: JSON.stringify({
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          phone: this.formData.phone,
          cc: this.formData.cc,
          email: this.formData.email,
        }),
      }
      voteService.createVote(data).then((res) => {
        console.log(res)
      })
    },
  },
}
</script>
