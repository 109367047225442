import axios from "axios";
import configService from "../../configs/config";

const voteService = {};
voteService.getVotes = () => {
  return axios
  .get(`${configService.apiUrl}/transactions`)
  .then(transactions => transactions.data.data)
  .catch(error => error)
};

voteService.getVote = id => {
  console.log(id)
  return axios
    .get(`${configService.apiUrl}/transactions/${id}`)
    .then(transaction => transaction.data.transaction)
    .catch(error => error);
};

voteService.createVote = (transaction)=> {
  return axios
    .post(`${configService.apiUrl}/transactions/create`, transaction)
    .then(transaction => transaction.data)
    .catch(error => error);
};

voteService.updateOrder = (quoteId, transaction) => {
  return axios
    .put(`${configService.apiUrl}/transactions/${quoteId}`, transaction)
    .then(transaction => transaction.data)
    .catch(error => error);
};

voteService.removeVote = id => {
  return axios
    .delete(`${configService.apiUrl}/transactions/${id}`)
    .then(transaction => transaction.data)
    .catch(error => error);
};

export default voteService;
